import React, {useState} from 'react';
import logo from './pain.png';
import './App.css';

function App() {

  interface IForm {
    flour_percent: number;
    water_percent: number;
    starter_percent: number;
    salt_percent: number;

    flour_base: number;
    water_base: number;
    starter_base: number;
    salt_base: number;

    flour_real: number;
    water_real: number;
    starter_real: number;
    salt_real: number;

    flour_corrected: number;
    water_corrected: number;
    starter_corrected: number;
    salt_corrected: number;
  }

  const [form, setFormValue] = useState<IForm>({
    flour_percent: 0,
    water_percent: 0,
    starter_percent: 0,
    salt_percent: 0,

    flour_base: 0,
    water_base: 0,
    starter_base: 0,
    salt_base: 0,

    flour_real: 0,
    water_real: 0,
    starter_real: 0,
    salt_real: 0,

    flour_corrected: 0,
    water_corrected: 0,
    starter_corrected: 0,
    salt_corrected: 0,
  });


  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      hydration: { value: number };
      flour: { value: number };
      starter: { value: number };
      salt: { value: number };
    };
    
    const hydration = Number(target.hydration.value); // typechecks!
    const flour = Number(target.flour.value); // typechecks!
    const starter = Number(target.starter.value); // typechecks!
    const salt = Number(target.salt.value); // typechecks!

    const flour_base = Math.round(flour);
    const water_base = Math.round(flour * (hydration/100));
    const starter_base = Math.round(flour * (starter/100));
    const salt_base = Math.round(flour * (salt/100));

    const flour_real = Math.round(flour + starter_base/2);
    const water_real = Math.round(water_base + starter_base/2);
    const starter_real = Math.round(starter_base);
    const salt_real = Math.round(salt_base);

    const flour_corrected = Math.round(flour_base);
    const water_corrected = Math.round(flour_real*hydration/100-(0.5*starter_base));
    const starter_corrected = Math.round(starter_base);
    const salt_corrected = Math.round(salt_base);

    
    setFormValue({...form, 
      "flour_percent": 100, 
      "water_percent": hydration,
      "starter_percent": starter,
      "salt_percent": salt,

      "flour_base": flour_base, 
      "water_base": water_base,
      "starter_base": starter_base,
      "salt_base": salt_base,

      "flour_real": flour_real, 
      "water_real": water_real,
      "starter_real": starter_real,
      "salt_real": salt_real,

      "flour_corrected": flour_corrected, 
      "water_corrected": water_corrected,
      "starter_corrected": starter_corrected,
      "salt_corrected": salt_corrected,
    })
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <form className="App-form"
          onSubmit={handleSubmit}
        >
          <div>
            <label className="App-form-label">
              Hydration (%):
              <input className="App-form-input" type="number" name="hydration" defaultValue="0"/>
            </label>
          </div>
          <div>
            <label className="App-form-label">
              Flour (g):
              <input className="App-form-input" type="number" name="flour" defaultValue="0"/>
            </label>
          </div>
          <div>
            <label className="App-form-label">
              Starter (%):
              <input className="App-form-input" type="number" name="starter" defaultValue="0"/>
            </label>
          </div>
          <div>
            <label className="App-form-label">
              Salt (%):
              <input className="App-form-input" type="number" name="salt" defaultValue="0"/>
            </label>
          </div>
          <div>
            <input type="submit" value="Calculate" />
          </div>
        </form>
        <div className='App-values'>
          <div>
            <p>flour: {form.flour_percent}%</p>
            <p>water: {form.water_percent}%</p>
            <p>starter: {form.starter_percent}%</p>
            <p>salt: {form.salt_percent}%</p>
          </div>
          <div>
            <p>Base Calc</p>
            <p>{form.flour_base}</p>
            <p>{form.water_base}</p>
            <p>{form.starter_base}</p>
            <p>{form.salt_base}</p>
          </div>
          <div>
            <p>Real</p>
            <p>{form.flour_real}</p>
            <p>{form.water_real}</p>
            <p>{form.starter_real}</p>
            <p>{form.salt_real}</p>
          </div>
          <div className='App-corrected'>
            <p>Corrected</p>
            <p>{form.flour_corrected}</p>
            <p>{form.water_corrected}</p>
            <p>{form.starter_corrected}</p>
            <p>{form.salt_corrected}</p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
